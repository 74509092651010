import { Injectable } from '@angular/core';
import { Permission, Role } from '@tcc-mono/classifact/shared/data-access/models';
import { AuthenticationService } from '@tcc-mono/shared/authentication/passport';
import { map, take, Observable } from 'rxjs';
import { PermissionsService } from './permissions.service';

// const canEditPerPage:  { [key: string]: string[] } = {
//   dashboard: ['admin'],
//   users: ['data scientist', 'admin'],
//   data: ['data scientist', 'admin'],
//   studies: ['game manager'],
//   teams: ['game manager', 'admin'],
//   games: ['game manager', 'admin']
// };
@Injectable({
  providedIn: 'root'
})
export class PermissionService {


  public constructor(
    private readonly _authService: AuthenticationService,
    private readonly _permisssionsService: PermissionsService) {}

  public canEdit(page: string): boolean {
    return true;
  }

  public getAll() : Observable<Permission[]> {
    return this._authService.me$.pipe(map((me) => {return me.permissions}));
  }

  public hasPermission(permission: string) : Observable<boolean> {
    return this._authService.me$.pipe(
      take(1),
      map((me) => { return me.permissions.find((p: any) => p.name == permission) ? true : false}),
    )

  }
}
