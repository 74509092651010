import { Injectable } from '@angular/core';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { LocalStorageService } from '@tcc-mono/shared/ui/theming';
import { BehaviorSubject, Observable, catchError, from, map, mergeMap, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SerialBluetoothService {

  private readonly BLUETOOTH_STORAGE_KEY: string = 'BluetoothId';
  private readonly _bluetoothId = new BehaviorSubject<string>(null);
  public readonly bluetoothId$: Observable<string> = this._bluetoothId.asObservable();

  public constructor(
    private readonly _bt: BluetoothSerial,
    private readonly _storage: LocalStorageService
  ) {
    this._bluetoothId.next(this._getStoredBluetoothDeviceId());
  }

  public connect(id: string): Observable<boolean> {
    return this._bt.connect(id)
      .pipe(
        tap(() => this.setStoredBluetoothDeviceId(id)),
        switchMap(() => this.selectIsConnected())
      );
  }

  public disconnect(): Observable<unknown> {
    return from(this._bt.disconnect());
  }

  private _getStoredBluetoothDeviceId(): string {
    return this._storage.get(this.BLUETOOTH_STORAGE_KEY);
  }

  public setStoredBluetoothDeviceId(id: string): void {
    this._storage.set({ key: this.BLUETOOTH_STORAGE_KEY, value: id });
    this._bluetoothId.next(id);
  }

  public selectIsEnabled = (): Observable<boolean> => {
    return from(this._bt.isEnabled())
      .pipe(
        catchError(() => of(false))
      );
  }

  public selectIsConnected = (): Observable<boolean> => {
    return from(this._bt.isConnected())
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public selectIsBluetoothIdSet = (): Observable<boolean> => {
    return this.bluetoothId$
      .pipe(
        map((id: string) => !!id)
      );
  }

  public selectAvailableDevices(): Observable<any[]> {
    return from(this._bt.list());
  }

  public data(): Observable<any> {
    return this._bt.subscribeRawData()
      .pipe(
        switchMap(() => this._bt.read()),
        tap((res: any) => alert('data: ' + res))
      );
  }

  public trigger(message: string): Observable<any> {
    return from(
      this._bt.write(message),
    ).pipe(
      switchMap(() => this._bt.read()),
      tap((res) => console.log('trigger: ' + res))
    );
  }


}
