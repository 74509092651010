/**
 * for online development
 */
export const environment = {
  production: false,
  apiUrl: 'https://coenen-planning-api-develop.tccapps.nl',
  appUrl: 'https://coenen-app-develop.tccapps.nl',
  appCode: 'CD-APP',
  authentication: {
    clientId: '99300dd0-d33e-45cd-a491-d4ef1d11605f',
    scope: '*',
    redirectUrl: 'https://coenen-app-develop.tccapps.nl/auth/callback',
    responseType: 'code',
    codeChallengeMethod: 'S256',
    renewTimeBeforeTokenExpiresInSeconds: 20,
    postLogoutRedirectUrl: 'https://coenen-app-develop.tccapps.nl',
    useTenants: false,
  }
};
