import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ImportResource, Import, IImport, ImportTemplate } from '@tcc-mono/classifact/shared/data-access/models';
import { BaseService, PaginatedResponse, Response } from '@tcc-mono/shared/data-access';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportService extends BaseService<Import> {
  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/imports'
  }


  getImports = (resource: ImportResource, params?: any) => {
    const url = this.getUrl(`${this.path}`);
    return this._http.get<PaginatedResponse<IImport>>(url, { params: params });
  }

  postImport = (file: File, collection_name: string, template: ImportTemplate, resource: ImportResource): Observable<Response<Import>> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('collection_name', collection_name);
    formData.append('template', template);

    return this._http.post<Response<Import>>(this.getUrl(`${this.path}`), formData);
  }
}
