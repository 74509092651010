export * from './lib/shared-data-access.module';
export * from './lib/models/response.model';
export * from './lib/models/entity-property.model';
export * from './lib/models/base.model';

export * from './lib/services/base.service';
export * from './lib/services/serial-bluetooth.service';


export * from './lib/state/base.repository';
export * from './lib/state/core.repository';
