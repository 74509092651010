import { Component } from '@angular/core';

@Component({
  selector: 'tcc-mono-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor() {}
}
