import { Injectable } from '@angular/core';
import { Store, createStore } from '@ngneat/elf';
import { deleteAllEntities, deleteEntities, getEntity, hasEntity, removeActiveIds, resetActiveIds, selectActiveEntity, selectAllEntities, selectEntity, setActiveId, setEntities, upsertEntities, withActiveId, withEntities } from '@ngneat/elf-entities';
import { BaseService } from '../services/base.service';
import { BehaviorSubject, EMPTY, Observable, distinctUntilChanged, finalize, map, switchMap, take, tap } from 'rxjs';
import { Params } from '@angular/router';
import { PaginationData } from '@ngneat/elf-pagination';
import { ElfUtil } from '@tcc-mono/shared/utils';

@Injectable({
  providedIn: 'root'
})
export abstract class CoreRepository<TEntity extends Record<string, any>> {

  private _store: Store;

  protected _params = new BehaviorSubject<Params>({});
  public params$: Observable<Params> = this._params.asObservable();

  protected _loading = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loading.asObservable();

  protected _pagination = new BehaviorSubject<PaginationData<number>>(null);
  public pagination$: Observable<PaginationData<number>> = this._pagination.asObservable();

  public entities$: Observable<Array<TEntity>>;
  public activeEntity$: Observable<TEntity>;

  constructor(
    protected readonly storeData: {
      name: string
    },
    protected readonly _service: BaseService<TEntity>,
    private idPropertyKey: string = 'id'
  ) {
    this._store = createStore(
      { name: this.storeData?.name },
      withEntities<TEntity, string>({ idKey: this.idPropertyKey }),
      withActiveId()
    );

    this.entities$ = this._store.pipe(selectAllEntities());

    this.activeEntity$ = this._store.pipe(selectActiveEntity());
  }

  public listenToParams = (): Observable<unknown> => {
    return this.params$
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        switchMap((params: Params) => this._getEntities(params)),
        tap((entities: TEntity[]) => this._store.update(setEntities(entities)))
      );
  }

  public scrollLoad(event: any): void { // this is an IonInfiniteCustomEvent, which does not exist as a model.. therefor any
    if (this._pagination?.value?.currentPage < this._pagination?.value?.lastPage) {

      const params = {
        ...this._params.value,
        page: (this._pagination?.value?.currentPage ?? 0) + 1
      };

      this._getEntities(params)
        .pipe(
          take(1),
          tap((entities: TEntity[]) => this._store.update(upsertEntities(entities)))
        )
        .subscribe({
          next: () => event.target.complete()
        });

    } else {
      event.target.disabled = true;
    }
  }

  public pullRefresh(event: any): void {
    const params = {
      ...this._params.value,
      page: 1
    };

    this._getEntities(params)
      .pipe(
        take(1),
        tap((entities: TEntity[]) => this._store.update(setEntities(entities)))
      )
      .subscribe({
        next: () => event.target.complete()
      });
  }

  public resetParams(params: Params = {}): void {
    this._params.next(params);
  }

  public updateParams(params: Params): void {
    const newParams = {
      ...this._params.value,
      page: 1,
      ...params, //on purpose this order if for some reason someone needs to overwrite the page
    };

    this._params.next(newParams);
  }

  public removeParam(param: string): void {
    const paramCopy = {
      ...this._params.value
    } as Params;

    delete paramCopy[param];

    this._params.next(paramCopy);
  }

  public refreshPage = (page: number = 1): Observable<TEntity[]> => {
    return this._getEntities({
      ...this._params.value,
      page
    })
      .pipe(
        tap((entities: TEntity[]) => this._store.update(setEntities(entities)))
      );
  }

  public selectEntity = (enityId: PropertyKey): Observable<TEntity> => {
    return this._store.pipe(selectEntity(enityId));
  }

  public addEntity = (entity: Partial<TEntity>): Observable<TEntity> => {
    this._loading.next(true);
    return this._service.post(entity)
      .pipe(
        finalize(() => this._loading.next(false)),
        map(({ data }) => data),
        tap((entity) => this._store.update(upsertEntities(entity))),
        tap((entity) => this.setActiveEntity(entity[this.idPropertyKey]))
      );
  }

  public updateEntity = (entityId: PropertyKey, entity: TEntity): Observable<TEntity> => {
    this._loading.next(true);
    return this._service.put(entityId.toString(), entity)
      .pipe(
        finalize(() => this._loading.next(false)),
        map(({ data }) => data),
        tap((entity) => this._store.update(upsertEntities(entity)))
      );
  }

  public getEntity = (entityId: PropertyKey, setActive: boolean = true, forceGet: boolean = false, params: Params = {}): Observable<TEntity> => {
    if (!entityId) {
      return EMPTY;
    }

    if (setActive) {
      this.setActiveEntity(entityId)
    }

    const call = this._service.get(entityId.toString(), params)
      .pipe(
        map(({ data }) => data),
        tap((entity) => this._store.update(upsertEntities(entity)))
      );

    return (forceGet || !this._store.query(hasEntity(entityId))) ? call : EMPTY;
  }

  public removeEntity = (entityId: PropertyKey): Observable<unknown> => {
    this._loading.next(true);
    return this._service.delete(entityId.toString())
      .pipe(
        finalize(() => this._loading.next(false)),
        tap(() => this._store.update(deleteEntities(entityId)))
      );
  }

  public addEntitiesInStore = (entities: Partial<TEntity>[]): void => {
    this._store.update(upsertEntities(entities));
  }

  public removeEntityFromStore = (entityId: PropertyKey): void => {
    this._store.update(deleteEntities(entityId));
  }

  public setActiveEntity = (entityId?: PropertyKey): void => {
    if (entityId) {
      this._store.update(setActiveId(entityId));
    } else {
      this._store.update(setActiveId([]));
    }
  }

  public hasEntity = (entityId: PropertyKey): boolean => {
    return this._store.query(hasEntity(entityId));
  }

  public entityHasProperty = (entityId: PropertyKey, property: string): boolean => {
    if (!this.hasEntity(entityId)) {
      return false;
    }
    const entity = this._store.query(getEntity(entityId));

    return entity ? Object.hasOwn(entity, property) : false;
  }

  public resetStore = (): void => {
    this._store.update(deleteAllEntities());
  }

  private _getEntities = (params: Params): Observable<TEntity[]> => {
    this._loading.next(true);
    return this._service.getAll(params)
      .pipe(
        finalize(() => this._loading.next(false)),
        tap((result) => {
          if (result.meta) {
            this._pagination.next(ElfUtil.convertMetaToElfPagination(result.meta));
          }
        }),
        map(({ data }) => data)
      );
  }
}
