import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GameTask, Task } from '@tcc-mono/classifact/shared/data-access/models';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { BaseService } from '@tcc-mono/shared/data-access';

@Injectable({ providedIn: 'root' })
export class GameTaskService extends BaseService<GameTask> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/game/tasks'
  }

}
