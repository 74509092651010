import { Role } from './role.model';
import { Tenant } from './tenant.model';
export interface IUser {
  created_at: string;
  email: string;
  email_verified_at: string;
  id: string;
  name: string;
  updated_at: string;
  team_id: string;
  roles: Role[];
  tenants: Tenant[];
}

export class User implements IUser {
  created_at: string;
  email: string;
  email_verified_at: string;
  id: string;
  name: string;
  updated_at: string;
  team_id: string;
  roles: Role[];
  tenants: Tenant[];
}
