import { ImportService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import {
  Import,
  ImportResource,
  ImportTemplate
} from '@tcc-mono/classifact/shared/data-access/models';
import { CoreRepository } from '@tcc-mono/shared/data-access';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportRepository extends CoreRepository<Import> {
  public constructor(protected _importService: ImportService) {
    super({ name: 'imports' }, _importService);
  }

  postImport = (
    file: File,
    collection_name: string,
    template: ImportTemplate,
    resource: ImportResource
  ) => {
    return this._importService
      .postImport(file, collection_name, template, resource)
      .pipe(
        map(({ data }) => data),
        tap((imp: Import) => {
          this.setActiveEntity(imp.id);
        })
      );
  };
}
