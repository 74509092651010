import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicRouteStrategy } from '@ionic/angular';
import { AuthenticationModule } from '@tcc-mono/shared/authentication/passport';
import { SharedUiServiceModule } from '@tcc-mono/shared/ui/services';
import {
  AnimationService,
  FontawesomeTypeService,
  LanguageService
} from '@tcc-mono/shared/ui/theming';
import { CoenenAppShellRoutingModule } from './coenen-app-shell-routing.module';
import { registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
registerLocaleData(nl);

import isToday from 'dayjs/plugin/isToday';
import dayjs from 'dayjs';

dayjs.extend(isToday);

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    CoenenAppShellRoutingModule,
    AuthenticationModule.forRoot(),
    SharedUiServiceModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
  ]
})
export class CoenenAppShellModule {
  public constructor(
    private readonly _animationService: AnimationService,
    private readonly _fontawesomeService: FontawesomeTypeService,
    private readonly _languageService: LanguageService
  ) {
    this._animationService.initTheme();
    this._fontawesomeService.initTheme();
    this._languageService.initLang();
  }
}
