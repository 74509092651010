import { BaseModel } from '@tcc-mono/shared/data-access';
import { Avatar } from './avatar.model';

export class Player extends BaseModel {
  id: string;
  created_at?: string;
  description?: string;
  nickname?: string;
  updated_at?: string;
  user_id: string;
  avatar?: Avatar;
  avatar_id?: string;
}
