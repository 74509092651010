import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CoenenAppShellModule } from '@tcc-mono/coenen/app/shell/feature';
import { getAppConfigProvider } from '@tcc-mono/shared/app-config';
import { environment } from '../environments/environment';
import { SharedTranslationModule } from '@tcc-mono/shared/translation';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [IonicModule.forRoot(), HttpClientModule, SharedTranslationModule.forRoot(environment), CoenenAppShellModule],
  providers: [InAppBrowser, getAppConfigProvider(environment)],
  bootstrap: [AppComponent]
})
export class AppModule {}
