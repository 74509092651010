import { switchMap } from 'rxjs/operators';
import { forkJoin, map, Observable } from 'rxjs';
import { CoreRepository } from '@tcc-mono/shared/data-access';
import { Injectable } from '@angular/core';
import { GameService, LabellingSpecService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { Game, LabellingSpec } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class GameRepository extends CoreRepository<Game> {

  public activeGame$: Observable<Game> = this.activeEntity$;
  public games$: Observable<Game[]> = this.entities$;

  constructor(
    _gameService: GameService,
    private _labellingSpecService: LabellingSpecService
  ) {
    super(
      { name: 'games' },
      _gameService
    );
  }

  public setActiveGame = (game: Game): void => {
    this.setActiveEntity(game.id);
  }

  public addGame = (game: Game): Observable<Game> => {
    return this.addEntity(game);
  }

  public updateGame = (game: Game): Observable<Game> => {
    return this.updateEntity(game.id, game);
  }

  public deleteGame = (game: Game): Observable<unknown> => {
    return this._labellingSpecService.getAll({ game_id: game.id })
      .pipe(
        map(({ data }) => data),
        switchMap((specs: LabellingSpec[]) => {
          return forkJoin([
            ...specs.map(spec => {
              return this._labellingSpecService.put(spec.id, {
                ...spec,
                game_id: null
              })
            })
          ])
        }),
        switchMap(() => this.removeEntity(game.id))
      );
  }

}
