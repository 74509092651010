
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { PaginatedResponse, Response } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class BaseService<TEntity> {

  public path: string = '';

  constructor(
    protected _http: HttpClient,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig
  ) { }

  public get = (id: string | number, params: Object = {}): Observable<Response<TEntity>> => {
    return this._http.get<Response<TEntity>>(this.getUrl(this.path + '/' + id), { params: Object(params) })
  }

  public getAll = (params: Object = {}, customUrl?: string): Observable<PaginatedResponse<TEntity>> => {
    const usedUrl = this.getUrl(customUrl ? `${this.path}/${customUrl}` : this.path);
    return this._http.get<PaginatedResponse<TEntity>>(usedUrl, { params: Object(params) });
  }

  public getChild = <ChildEntity>(id: string | number, childUrl: string, params: Object = {}) => {
    const url = this.getUrl(this.path + '/' + id + '/' + childUrl);
    return this._http.get<ChildEntity>(url, { params: Object(params) })
  }

  public post = (body: Partial<TEntity>, customUrl?: string, params: Object = {}): Observable<any> => {
    const usedUrl = this.getUrl(customUrl ? `${this.path}/${customUrl}` : this.path);
    return this._http.post<any>(usedUrl, body, { params: Object(params) });
  }

  public postChild = <ChildEntity>(body: any, customUrl?: string, params: Object = {}): Observable<any> => {
    const usedUrl = this.getUrl(customUrl ? `${this.path}/${customUrl}` : this.path);
    return this._http.post<ChildEntity>(usedUrl, body, { params: Object(params) });
  }

  public put = (id: string | number, body: any, customUrl?: string): Observable<any> => {
    const usedUrl = this.getUrl(`${this.path}/${id}` + (customUrl ? `/${customUrl}` : ''));
    return this._http.put<any>(usedUrl, body);
  }

  public delete = (id: string | number, customUrl?: string): Observable<any> => {
    const usedUrl = this.getUrl(`${this.path}/${id}` + (customUrl ? `/${customUrl}` : ''));
    return this._http.delete<any>(usedUrl);
  }

  public getUrl = (uri?: string): string => {

    /** Add API url base path */

    if (!uri || !uri?.match(/^http/i)) {
      uri = this.appConfig.apiUrl + (uri ? ('/' + uri).replace(/^\//, '').replace(/\/{2,}/g, '/') : '');
    }

    /** Return */

    return uri;
  }

}
