import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService, PaginatedResponse, Response } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Player, Team } from '@tcc-mono/classifact/shared/data-access/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService extends BaseService<Player> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/players'
  }

  public invite = (user: Partial<Player>) => {
    return this._http.post(this.getUrl(this.path + '/invite'), user);
  }

  public getPlayersByUserId = (userId: string): Observable<Response<Player>> => {
    return this._http.get<Response<Player>>(`${this.getUrl(this.path)}/by-user/${userId}`);
  }

  public getTeams = (userId: string): Observable<PaginatedResponse<Team>> => {
    return this.getChild<PaginatedResponse<Team>>(userId, 'teams');
  }
}
